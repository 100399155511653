exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bespoke-services-js": () => import("./../../../src/pages/bespoke-services.js" /* webpackChunkName: "component---src-pages-bespoke-services-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inquiries-js": () => import("./../../../src/pages/inquiries.js" /* webpackChunkName: "component---src-pages-inquiries-js" */),
  "component---src-pages-luxury-villa-homes-in-greece-js": () => import("./../../../src/pages/luxury-villa-homes-in-greece.js" /* webpackChunkName: "component---src-pages-luxury-villa-homes-in-greece-js" */),
  "component---src-pages-our-places-js": () => import("./../../../src/pages/our-places.js" /* webpackChunkName: "component---src-pages-our-places-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sitelink-searchbox-js": () => import("./../../../src/pages/sitelink-searchbox.js" /* webpackChunkName: "component---src-pages-sitelink-searchbox-js" */),
  "component---src-pages-villas-for-rent-in-naxos-js": () => import("./../../../src/pages/villas-for-rent-in-naxos.js" /* webpackChunkName: "component---src-pages-villas-for-rent-in-naxos-js" */),
  "component---src-pages-villas-for-rent-in-paros-js": () => import("./../../../src/pages/villas-for-rent-in-paros.js" /* webpackChunkName: "component---src-pages-villas-for-rent-in-paros-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-island-js": () => import("./../../../src/templates/island.js" /* webpackChunkName: "component---src-templates-island-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-villa-js": () => import("./../../../src/templates/villa.js" /* webpackChunkName: "component---src-templates-villa-js" */)
}

